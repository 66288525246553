html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'SF Pro Display', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SF-Pro-Display-Regular.ttf');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SF-Pro-Display-Medium.ttf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SF-Pro-Display-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}
